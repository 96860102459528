<template>
<!--  <container/>-->
  <router-view/>
</template>

<script>
import container from "@/page/container";

export default {
  name: 'App',
  components: {
    container
  }
}
</script>

<style>

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

ul,
ol,
li {
  list-style: none;
}

input {
  background-color: transparent;
  border: 0;
  outline: none;
  color: #050505;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #050505 !important;
  caret-color: #050505;
  transition: background-color 0s linear 3600s;
}

/*滚动条整体样式*/
html ::-webkit-scrollbar {
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}

/*滚动条里面小方块*/
html ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.4);
}

/*滚动条里面轨道*/
html ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(156, 183, 214, 1);
  border-radius: 0;
  background: rgba(156, 183, 214, 0.97);
}
</style>

export const menuList = [
    // {
    //     label: '风预报',
    //     value: 'windy',
    //     path: '/forecast-windy'
    // },
    // {
    //     label: '雾预报',
    //     value: 'fog',
    //     path: '/forecast-fog'
    // },
    {
        label: ' ',
        value: 'forecast',
        path: '/forecast'
    }
]

import axios from 'axios'
import QS from 'qs'
import store from '@/store/index'
import {message} from 'ant-design-vue';
import {createRouter as router} from "vue-router/dist/vue-router.mjs";


/**
 * 【指定 axios的 baseURL】
 * 如果手工指定 baseURL: '/love-cotton-boot'
 * 则映射后端域名，通过 vue.config.js
 * @type {*|string}
 */
let apiBaseUrl = "http://43.138.82.127:9000";
// console.log("apiBaseUrl= ",apiBaseUrl)
// 创建 axios 实例
const service = axios.create({
    baseURL: apiBaseUrl,
    timeout: 9000,  // 请求超时时间
})
service.defaults.baseURL = apiBaseUrl;
// 环境的切换
// if (process.env.NODE_ENV === 'development') {
//     axios.defaults.baseURL = 'http://43.138.82.127:9000';}
// else if (process.env.NODE_ENV === 'debug') {
//     axios.defaults.baseURL = 'http://43.138.82.127:9000';
// }
// else if (process.env.NODE_ENV === 'production') {
//     axios.defaults.baseURL = 'http://43.138.82.127:9000';
// }

service.defaults.timeout = 9000;
service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 每次发送请求之前判断vuex中是否存在token
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = JSON.parse(store.state.token);
        token && (config.headers.Authorization = token);
        return config;
    },
    error => {
        // app.prototype.$message.error('请求超时')
        return Promise.error(error);
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    router.push({
                        path: '/login',
                        // query: {
                        //     redirect: router.currentRoute.fullPath
                        // }
                    });
                    break;
                // 403 token过期
                // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面
                case 1006:
                case 403:
                    message.error(error.response.data.data).then(r => {
                        localStorage.removeItem('token');
                        store.commit('clearUserInfo', null);
                        store.commit('clearToken', null);
                    })
                    break;
                // 404请求不存在
                case 404:
                    message.error('网络请求不存在').then(r => {});
                    break;
                case 429:
                    message.error('请求频繁，请稍后再试').then(r => {});
                    break;
                case 422:
                    message.error('请求失败').then(r => {});
                    break;
                case 500:
                case 502:
                    router.push({
                        path: '/login',
                    });
                    localStorage.removeItem('token');
                    store.commit('clearUserInfo', null);
                    store.commit('clearToken', null);
                    message.error('服务器内部错误，请联系开发人员').then(r => {});
                    break;
                case 1002:
                    router.push({
                        path: '/login',
                        // query: {
                        //     redirect: router.currentRoute.fullPath
                        // }
                    });
                    message.error('用户不存在').then(r => {});
                    break;
                case 1005:
                    message.error('密码错误，请检查后登录').then(r => {
                        return false
                    });
                    break;
                // 其他错误，直接抛出错误提示
                default:
                    message.info(error.response.data.message).then(r => {});
            }
            return Promise.reject(error.response);
        } else {
            router.push({
                path: '/login',
            });
        }
    }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        service.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]，表单数据
 */
export function post(url, params) {
    // console.log('请求体数据：', QS.stringify(params))
    return new Promise((resolve, reject) => {
        service.post(url, QS.stringify(params))
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]，json对象
 */
export function handlePost(url, params) {
    let token = JSON.parse(store.state.token)
    return new Promise((resolve, reject) => {
        service({
            url: url,
            method: 'post',
            data: params,
            headers: {"token": token}
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
                // console.log('请求返回：', err)
            })
    });
}

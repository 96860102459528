import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/forecast',
        name: 'screen',
        meta: {
            title: '预报',
        },
        component: () => import('../page/container.vue'),
        beforeEnter: (to, from, next) => {
            if (!localStorage.getItem('token')) {
                if (to.name === 'login') {
                    router.replace('/login')
                    next();
                } else {
                    next('/login'); // 更改为 next 函数调用
                    // 此处的逻辑可能需要根据实际需求进行调整
                }
            } else {
                next();
            }
        },
        children: [
            {
                path: 'forecast',
                name: 'forecast',
                meta: {
                    title: '预报数据',
                    keepAlive: true,
                },
                component: () => import('../views/forecast.vue'),
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录',
            requireAuth: false,
        },
        component: () => import('../components/userLogin/login.vue'),
    },
    {
        path: '/logon',
        name: 'logon',
        meta: {
            title: '注册',
            requireAuth: false,
        },
        component: () => import('../components/userLogin/logon.vue'),
    },
    // 其他路由...
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

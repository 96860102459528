
import {defineComponent, reactive, ref, toRaw} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from "vuex";
import {login} from "../../request/api.js";
import JSEncrypt from 'jsencrypt'

export default defineComponent({
  setup() {
    const store = useStore()

    const router = useRouter()

    const formState = reactive({
      username: '',
      password: '',
      remember: true,
    });

    let loading = ref(false)
    const onSubmit = async () => {
      try {
        loading.value = true;
        // 加密和请求逻辑...
        const info = toRaw(formState)
        const encryptor = new JSEncrypt();
        const pubKey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAm3IZ9mxV81siJQQYzIqZYfuqpV1Wpv+rAXjY5e1VK58ItLzQ1nJ8g9R05wEEsJpboVIUVFuTQp56DNi4qXPZxWTSb4tozt6Pa57h+ePI6/2qRGZHHR8OmZkz2VQXTjfev5j/7P8Mq0//VpMktBA9s+Z59oW/m5OBtqEh/ynJlN9PnC2PbkAwtH57yUwGl1XAxMd0UpmyRoYe0DrCX50H80u8S+ZZ6t9M45YSrsNqJsVBW30/J2XXdDvl5EOdC+agYHwtHw4rmSJpKHghV80hYBZXZBm+sgaNBXXh1N3CgVvNwRzJSwRfD5u69hAnYL0cNzlHnSwdQOwbm0eJMKJh6wIDAQAB";
        encryptor.setPublicKey(pubKey)
        let userInfo = {
          'username': info.username,
          'password': encryptor.encrypt(info.password)
        }
        const response = await login(userInfo);

        let res = response.data;
        let user = {
          "username": res.username,
          "nickname": res.nickname
        }
        let pointList = res.points
        store.commit("setToken", JSON.stringify(res.token))
        store.commit("setUserInfo", JSON.stringify(user))
        store.commit("setPoints", JSON.stringify(pointList))

        if (res.token) {
          // 更新状态和进行路由跳转...
          loading.value = false
          await router.push('/forecast');
        } else {
          return false;
        }
      } catch (error) {
        console.error('登录失败:', error);
      } finally {
        loading.value = false;
      }
    };

    return {
      formState,
      onSubmit,
      store,
      loading
    }
  }
})

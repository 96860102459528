import { createStore, Store, useStore as baseUseStore } from 'vuex';

// 定义 State 接口
interface State {
    token: string | null;
    userInfo: Record<string, any>;
    points: any[];
    currentPoint: string;
    currentDate: string;
}

// 定义 useStore 函数
export function useStore(): Store<State> {
    return baseUseStore();
}

// 创建 store
export default createStore<State>({
    state: {
        token: localStorage.getItem("token") || null,
        userInfo: {},
        points: [],
        currentPoint: "",
        currentDate: "",
    },
    mutations: {
        setToken(state, payload: string) {
            state.token = payload;
            localStorage.setItem("token", payload);
        },
        // ... 其他 mutations
        clearToken() {
            localStorage.removeItem("token");
        },
        setPoints(state, payload) {
            state.points = payload
            localStorage.setItem("points", payload)
        },
        clearPoints() {
            localStorage.removeItem("points")
        },
        setUserInfo(state, payload) {
            state.userInfo = payload
            localStorage.setItem("userInfo", payload)
        },
        clearUserInfo() {
            localStorage.removeItem("userInfo");
        },
        setCurrentPoint(state, payload) {
            state.currentPoint = payload
            localStorage.setItem("currentPoint", payload)
        },
        clearCurrentPoint() {
            localStorage.removeItem("currentPoint");
        },
        setCurrentDate(state, payload) {
            state.currentDate = payload
            localStorage.setItem("currentDate", payload)
        },
        clearCurrentDate() {
            localStorage.removeItem("currentDate");
        },
    },
    actions: {
        SETTOKEN({ commit }, payload: string) {
            commit("setToken", payload);
        },
        CLEARTOKEN(context) {
            context.commit("clearToken");
        },
        SETPOINTS(context, payload) {
            context.commit("setPoints", payload);
        },
        CLEARPOINTS(context) {
            context.commit("clearPoints");
        },
        SETUSERINFO(context, payload) {
            context.commit("setUserInfo", payload)
        },
        CLEARUSERINFO(context) {
            context.commit("clearUserInfo");
        },
        SETCURRENTPOINT(context, payload) {
            context.commit("setToken", payload);
            console.log('当前的点位信息啊啊啊啊啊啊啊啊啊：', payload)
        },
        CLEARCURRENTPOINT() {
            localStorage.removeItem("clearCurrentPoint");
        },
        SETCURRENTDATE(context, payload) {
            context.commit("setCurrentDate", payload);
            console.log('当前的时间：', payload)
        },
        CLEARCURRENTDATE() {
            localStorage.removeItem("clearCurrentDate");
        },
        // ... 其他 actions
    },
    // ... modules 和 getters
    modules: {},
    getters: {},
});
